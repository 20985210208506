const brandImages = [
  {
    brandName: "thetaxicentre",
    agentImage: "https://www.thetaxicentre.com/custom/126960.jpg",
    notificationImage: "https://www.thetaxicentre.com/custom/127347.jpg",
  },
  {
    brandName: "vansdirect",
    agentImage: "https://www.vansdirect.co.uk/custom/133752.png",
    notificationImage: "https://www.vansdirect.co.uk/custom/133753.png",
  },
];

export default brandImages;
